import React from "react"

import Layout from "../components/Layout/Layout"
import Container from "../components/Container"
import Flex from "../components/Flex"
import SEO from "../components/SEO/seo"
import Heading1 from "../components/Headings/Heading1"
import Paragraph from "../components/Paragraph"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Flex flexDirection={"column"} height={"80vh"} textAlign={"center"}>
        <Heading1>NOT FOUND</Heading1>
        <Paragraph>
          You just hit a route that doesn&#39;t exist... the sadness.
        </Paragraph>
      </Flex>
    </Container>
  </Layout>
)

export default NotFoundPage
